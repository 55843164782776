.parallax-texts-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  height: 100svh;
  width: 1440px;
  max-width: calc(100% - 15px);
  margin: 0 auto;

  .parallax-text {
    position: absolute;
    z-index: 0;
    font-size: 12px;
    font-weight: 600;
    animation-name: float;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: var(--animation-time);
    transition: transform 0.1s;
    mix-blend-mode: exclusion;
    white-space: nowrap;
    font-weight: 300;
    font-size: 10px;
    color: #fff;
    letter-spacing: 1px;

    @media (max-width: 575.98px) {
      font-size: 8px;
    }

    &:nth-child(1) {
      top: 20%;
      left: 30%;

      @media (max-width: 575.98px) {
        top: 20%;
        left: 5%;
      }
    }

    &:nth-child(2) {
      top: 45%;
      left: 10%;

      @media (max-width: 575.98px) {
        left: 5%;
      }
    }

    &:nth-child(3) {
      top: 80%;
      left: 60%;

      @media (max-width: 575.98px) {
        top: 70%;
        left: 49%;
      }
    }

    &:nth-child(4) {
      top: 55%;
      right: 10%;

      @media (max-width: 575.98px) {
        right: 5%;
      }
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 30%;
      right: 20%;
      color: #999;
      opacity: .6;

      @media (max-width: 575.98px) {
        top: 12%;
        right: 5%;
      }
    }

    &:nth-child(6) {
      top: 70%;
      left: 15%;

      @media (max-width: 575.98px) {
        top: 82%;
        left: 5%;
      }
    }
  }
}

@keyframes float {
  0% { margin-top: 0; }
  50%  { margin-top: 15px; }
  100%   { margin-top: 0; }
}
