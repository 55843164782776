.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  min-height: 100svh;
  justify-content: center;
  align-items: center;
  background-color: #000508;
  background-image: radial-gradient(farthest-side at bottom left,#271d3b,transparent 900px),radial-gradient(farthest-corner at bottom right,#3a233f,transparent 1000px);

  @media (max-width: 991.98px) {
    background-image: radial-gradient(farthest-corner at bottom right,#3a233f,transparent 250px);
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    width: 100%;
    height: 100vh;
    height: 100svh;
  }

  &__header {
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;

    div {
      display: flex;
      align-items: center;
      gap: 6px;
      position: absolute;
      top: 15px;
      right: 15px;

      @media (max-width: 575.98px) {
        font-size: 8px;
      }

      &:first-child {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 1;

        a {
          color: inherit;
          text-decoration: line-through;
        }

        @media (max-width: 991.98px) {
          top: 15px;
          left: 15px;
        }
      }

      &:last-child {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 1;

        @media (max-width: 991.98px) {
          top: 15px;
          right: 15px;
        }
      }
    }

    span {
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background: #68c498;
    }
  }

  &__parallax_texts {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__particle_avatar {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }

  &__heading {
    font-family: "vortice-concept", sans-serif;
    font-size: 8vw;
    line-height: 0.5;
    -webkit-text-stroke: 2px #fff;
    -webkit-text-fill-color: transparent;
    color: transparent;
    margin: 4vw 0 0 0;

    @media (max-width: 991.98px) {
      font-size: 9vw;
    }
  }

  &__subheading {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #fff;
    opacity: .6;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 991.98px) {
      font-size: 14px;
    }
  }

  &__subtext {
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #999;
    opacity: .6;
    text-transform: uppercase;
    margin-top: 32px;
    text-indent: 5px;

    @media (max-width: 991.98px) {
      text-indent: 2px;
    }

    a {
      color: inherit;
      text-decoration: line-through;
    }
  }

  &__scroll {
    position: absolute;
    bottom: 39px;
    right: 5%;
    cursor: pointer;

    @media (max-width: 991.98px) {
      display: none;
    }
  }
}

:global(.is-mobile) {
  .home {
    &__parallax_texts {
      pointer-events: none;
    }

    &__particle_avatar {
      pointer-events: none;
    }
  }
}
