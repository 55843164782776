.achievements {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 200vh;
  color: #fff;
  padding: 15px;

  @media (max-width: 991.98px) {
    justify-content: center;
    height: auto;
    min-height: 100vh;
    gap: 8rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: calc(100% - 8rem - 8rem);
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 8rem;
    z-index: 99999;
    mix-blend-mode: exclusion;

    @media (max-width: 1280px) {
      width: calc(100% - 4rem - 4rem);
      padding: 0 4rem;
    }

    @media (max-width: 991.98px) {
      height: auto;
      width: calc(100% - 4vw - 4vw);
      padding: 0 4vw;
    }

    &_heading {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 2px;
      color: #fff;
      opacity: .6;
      text-transform: uppercase;
      margin-bottom: 2vw;
  
      @media (max-width: 991.98px) {
        font-size: 12px;
      }
    }

    &_label {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;

      @media (max-width: 991.98px) {
        font-size: 10px;
      }
    }

    &_asset {
      display: none;
      margin-top: 8px;
      margin-bottom: 16px;

      > * {
        width: 360px;
        max-width: 100%;
      }

      @media (max-width: 991.98px) {
        display: block;
      }
    }

    &_title {
      font-family: "vortice-concept", sans-serif;
      font-size: 2.3vw;
      line-height: 1.3333;
      -webkit-text-stroke: 2px #fff;
      -webkit-text-fill-color: transparent;
      color: transparent;
      margin: 8px 0 2vw;

      @media (max-width: 991.98px) {
        font-size: max(3vw, 22px);
        margin: 0 0 16px;
      }
    }

    &_list {
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      color: white;
      letter-spacing: 2px;
      line-height: 1.5;

      @media (max-width: 991.98px) {
        font-size: 12px;
      }
    }
  }

  &__asset_container {
    position: absolute;
    top: 25vh;
    width: calc(100% - 30px);
    height: 175vh;
    z-index: -1;

    @media (max-width: 991.98px) {
      display: none;
    }
  }

  &__asset {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 25vh;
    padding-right: 15%;

    @media (max-width: 1512.98px) {
      padding-right: 10%;
    }

    @media (max-width: 1280px) {
      padding-right: 5%;
    }

    > * {
      width: 480px;
      height: auto;
      display: none;

      @media (max-width: 1280px) {
        width: 400px;
      }

      &:global(.active) {
        display: block;
      }
    }
  }
}
