.menu {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(.215,.61,.355,1);

  @media (max-width: 991.98px) {
    bottom: 15px;
  }

  &:global(.is-hidden) {
    opacity: 0;
    pointer-events: none;
  }

  &__list {
    display: flex;
    gap: 8px;
    list-style-type: none;
    padding: 5px;
    margin: 0;
    border-radius: 8px;
    background-color: rgba(0,0,0,.6);
    font-size: 10px;
    font-weight: 500;
    color: #fff;

    li {
      min-width: 108px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:global(.active) {
        border-radius: 5px;
        color: #32383d;
        background-color: #c5c5c6;
      }
    }
  }
}
