.footerContainer {
  position: relative;
  height: 200vh;
}

:global(body.is-bottom) {
  .footer {
    &__top_bar {
      opacity: 1;
    }
  }
}

.footer {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;

  &__container {
    max-width: 1920px;
  }

  .paragraph {
    display: flex;
    justify-content: center;
    font-weight: 900;
    font-size: min(7.8vw, 78px);
    text-transform: uppercase;
    line-height: 1.3;
    letter-spacing: 2px;
    flex-wrap: wrap;
    max-width: 90%;
    color: #fff;
    padding-bottom: 45px;

    @media (max-width: 991.98px) {
      font-size: 9.2vw;
    }

    @media (max-width: 575.98px) {
      padding-left: 12px;
    }

    .word {
      position: relative;
      margin-right: 12px;
      margin-top: 12px;

      .shadow {
        position: absolute;
        opacity: 20%;
      }
    }
  }

  &__top_bar {
    position: sticky;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    max-width: 1920px;
    width: calc(100% - 60px);
    padding: 30px;
    color: #fff;
    z-index: 9999;
    opacity: 0;
    transition: all 0.4s cubic-bezier(.215,.61,.355,1);

    @media (max-width: 991.98px) {
      padding: 15px;
      width: calc(100% - 30px);
    }

    > div {
      font-size: 10px;
      font-weight: 300;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #999;
      opacity: 0.6;
      text-decoration: none;
      cursor: pointer;

      @media (max-width: 575.98px) {
        font-size: 8px;
      }
    }
  }

  &__bottom_bar {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    max-width: 1920px;
    width: calc(100% - 60px);
    padding: 30px;
    color: #fff;
    z-index: 9999;

    @media (max-width: 991.98px) {
      padding: 15px;
      width: calc(100% - 30px);
    }
  }

  &__bottom_item {
    display: flex;
    flex-direction: column;
    letter-spacing: 1px;
    gap: 6px;
    font-size: 10px;
    font-weight: 300;

    @media (max-width: 575.98px) {
      font-size: 8px;
    }

    a {
      color: #999;
      opacity: .6;
      text-decoration: none;
      transition: all 0.4s cubic-bezier(.215,.61,.355,1);

      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
  }

  &__bottom_item:nth-child(2) {
    text-align: center;
  }

  &__bottom_item:nth-child(3) {
    text-align: right;
  }
}
