.archive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 25vh;

  @media (max-width: 991.98px) {
    padding-top: calc(25vh - 90px);
    padding-bottom: calc(25vh - 90px - 15px);
  }

  &__heading {
    font-size: 9.25vw;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;

    @media (max-width: 991.98px) {
      font-size: 9vw;
    }
  }
}
