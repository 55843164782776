@import './vendors/lenis.scss';

:root {
  --animation-time: 2s;
  --blur-purple-gradient: linear-gradient(180deg, #8E76D5 0%, #37213d 100%);
  --blur-1-size: clamp(200px, 25vw, 25vw);
  --blur-2-size: clamp(300px, 40vw, 40vw);
  --blur-3-size: clamp(100px, 10vw, 10vw);
  --blur-amount: clamp(40px, 5vw, 90px);
}

html {
  -webkit-text-size-adjust: 100%;
  height: -webkit-fill-available;
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  overscroll-behavior: none;
  overflow-x: hidden;
}

.gradient__background_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

body.is-mobile {
  .gradient__background {
    overflow-x: hidden;
  }
}

.gradient__background {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: var(--z-index-header);
  pointer-events: none;
}

.gradient__background::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  content: '';
}

.gradient__background .blur {
  position: absolute;
  border-radius: 900px;
  filter: blur(var(--blur-amount));
  background: var(--blur-purple-gradient);
  opacity: 0.16;
  z-index: 1;
}

.gradient__background .blur:nth-child(1) {
  height: var(--blur-1-size);
  width: calc(var(--blur-1-size) * 1);
  top: 0;
  left: 0;
}

.gradient__background .blur:nth-child(2) {
  height: var(--blur-2-size);
  width: calc(var(--blur-2-size) * 0.75);
  top: 20vh;
  right: 20vh;
}

.gradient__background .blur:nth-child(3) {
  height: var(--blur-3-size);
  width: calc(var(--blur-3-size) * 2);
  bottom: 20vh;
  left: 20vw;
}

.link {
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.link.shuffling {
  opacity: 0.8;
}

.noise::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  opacity: .03;
  z-index: 99999999;
  pointer-events: none;
  background: url('../assets/images/noise.gif');
}

.cursor-image {
  position: fixed;
  background: url('../assets/images/rocket.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  z-index: 1000;
  user-select: none;
  overflow: hidden;
  pointer-events: none;
  opacity: .7;
  transform: translate(35px, 35px);
  transition: background-image .3s ease-in-out;
  z-index: 99999999;

  @media (max-width: 991.98px) {
    display: none;
  }
}

$n: 10;

@for $i from 1 through $n {
  $v: random(90) + 9;
  $h: random(250) + 50;
  $d: random(70)/10 + 3;

  .meteor-#{$i} {
    position: absolute;
    top: $h + px;
    left: $v * 1%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    animation: meteor $d+s linear infinite;
    will-change: transform, opacity;
    /* Hint for browser optimization */

    &:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 5px;
      border-radius: 50%;
      margin-top: -2px;
      background: rgba(#fff, 0.7);
      box-shadow: 0 0 15px 3px #fff;
    }
  }
}

@keyframes meteor {
  0% {
    opacity: 1;
    transform: translate(0, -300px) rotate(-45deg);
    /* Use translate instead of margin */
  }

  12% {
    opacity: 0;
  }

  15% {
    opacity: 0;
    transform: translate(-600px, 300px) rotate(-45deg);
    /* Use translate instead of margin */
  }

  100% {
    opacity: 0;
  }
}

.custom-btn {
  position: relative;
  display: inline-block;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  width: fit-content;
  padding: 10px 25px;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.neon-btn {
  color: #fff;
  border: 1px solid#fff;
  box-shadow: 0 0 5px #fff, 0 0 5px #fff inset;
  z-index: 1;
}

.neon-btn:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background: #fff;
  box-shadow:
    0 0 20px #fff;
  transition: all 0.3s ease;
}

.neon-btn:hover {
  color: #32383d;
}

.neon-btn:hover:after {
  left: 0;
  width: 100%;
}

.neon-btn:active {
  top: 2px;
}

.is-mobile {
  .meteors {
    display: none;
  }

  .decorator {
    .cursor-image {
      display: none;
    }
  }
}

@keyframes loader-anim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

body.loading {
  overflow: hidden;
}

body.loading::before,
body.loading::after {
  content: '';
  position: fixed;
  z-index: 99999999;
}

body.loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000508;
  background-image: radial-gradient(farthest-side at bottom left, #271d3b, transparent 900px), radial-gradient(farthest-corner at bottom right, #3a233f, transparent 1000px);
}

body.loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: #8E76D5;
  animation: loader-anim 0.7s linear infinite alternate forwards;
}