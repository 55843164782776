.works {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  min-height: 400vh;
  background-color: #0d1116;
  padding: 15px;

  @media (max-width: 991.98px) {
    gap: 15px;
    min-height: unset;
  }

  &__label {
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #999;
    opacity: .6;
    position: absolute;
    left: calc(50% - 0.5px);
    transform: rotate(90deg);
    transform-origin: left center;
    top: 0;

    @media (max-width: 1439.98px) {
      left: calc(55% - 1.5px);
    }

    @media (max-width: 991.98px) {
      display: none;
    }
  }

  &__container {
    position: fixed;
    top: 15px;
    pointer-events: none;
    opacity: 0;
    z-index: 0;
    display: flex;
    gap: 15px;
    height: calc(100vh - 30px);
    max-width: 1920px;
    margin: 0 auto;

    @media (max-width: 991.98px) {
      position: relative;
      pointer-events: all;
      opacity: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
    }

    .works__asset > *,
    .works__content > * {
      opacity: 0;
      transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      @media (max-width: 991.98px) {
        opacity: 1;
      }
    }

    &:global(.active) {
      position: sticky;
      pointer-events: all;
      opacity: 1;
      z-index: 1;

      @media (max-width: 991.98px) {
        position: relative;
      }

      .works__asset > *,
      .works__content > * {
        opacity: 1;
      }
    }
  }

  &__asset {
    border-radius: 5px;
    width: 50%;
    background-color: #161b22;
    overflow: hidden;
    border: 1px solid #30363d;

    @media (max-width: 1439.98px) {
      width: 55%;
    }

    @media (max-width: 991.98px) {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: none;
      pointer-events: none;

      main {
        div {
          display: none;
        }
      }
    }
  }

  &__asset_wrapper {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding-bottom: 56.25%;
    margin-top: 24px;
    display: none;

    @media (max-width: 991.98px) {
      display: block;
    }

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: calc(50% - 8vw);
    background-color: #161b22;
    padding: 4vw;
    border: 1px solid #30363d;

    @media (max-width: 1439.98px) {
      width: calc(45% - 8vw);
    }

    @media (max-width: 991.98px) {
      width: calc(100% - 8vw);
      padding-top: 37.75px;
      padding-bottom: 0;
    }
  }

  &__heading {
    font-family: "vortice-concept", sans-serif;
    font-size: 2.6vw;
    line-height: 1.3333;
    -webkit-text-stroke: 2px #fff;
    -webkit-text-fill-color: transparent;
    color: transparent;
    margin: 3vw 0;

    @media (max-width: 991.98px) {
      font-size: max(3vw, 22px);
      margin: 30px 0;
    }
  }

  &__subheading {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    letter-spacing: 2px;
    line-height: 1.5;
    margin-bottom: 30px;

    @media (max-width: 991.98px) {
      font-size: 12px;
      max-width: 600px;
    }

    a {
      text-decoration: line-through;
      color: inherit;
    }
  }

  &__skills {
    display: flex;
    align-items: center;
    align-self: flex-end;
    width: 280px;
    height: 90px;
    mask-image: linear-gradient(270deg, transparent, #000 10%, #000 90%, transparent);
    margin-top: 15px;

    @media (max-width: 991.98px) {
      margin-top: 0;
    }

    @media (max-width: 575.98px) {
      width: 200px;
    }
  }

  &__skill {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-right: 8px;
    color: #fff;
  }

  &__scroll {
    position: absolute;
    bottom: 24px;
    right: calc(5% - 15px);
    cursor: pointer;

    @media (max-width: 991.98px) {
      display: none;
    }
  }
}
